form#user-login {
  margin: 4em 0;
  > div {
    max-width: 350px;
    margin: 2rem auto 0;
  }


  .form-item {
    label {
      text-align: left;
    }
  }

  input[type="text"], input[type="password"] {
    width: 100%;
  }

  input[type="submit"]{
    width: 100%;
  }

  .form-item-name, .form-item-pass {

  }

  .form-item-name {
  }

  .form-item-pass {
  }

  .form-actions {
  }

  .form-required{display: none;}
}
