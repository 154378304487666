@charset "UTF-8";
.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }

@media (min-width: 1366px) { .d-xxl-none { display: none !important; }
  .d-xxl-inline { display: inline !important; }
  .d-xxl-inline-block { display: inline-block !important; }
  .d-xxl-block { display: block !important; }
  .d-xxl-table { display: table !important; }
  .d-xxl-table-row { display: table-row !important; }
  .d-xxl-table-cell { display: table-cell !important; }
  .d-xxl-flex { display: flex !important; }
  .d-xxl-inline-flex { display: inline-flex !important; } }

@media (min-width: 1600px) { .d-xxxl-none { display: none !important; }
  .d-xxxl-inline { display: inline !important; }
  .d-xxxl-inline-block { display: inline-block !important; }
  .d-xxxl-block { display: block !important; }
  .d-xxxl-table { display: table !important; }
  .d-xxxl-table-row { display: table-row !important; }
  .d-xxxl-table-cell { display: table-cell !important; }
  .d-xxxl-flex { display: flex !important; }
  .d-xxxl-inline-flex { display: inline-flex !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

@media (min-width: 1366px) { .flex-xxl-row { flex-direction: row !important; }
  .flex-xxl-column { flex-direction: column !important; }
  .flex-xxl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xxl-wrap { flex-wrap: wrap !important; }
  .flex-xxl-nowrap { flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill { flex: 1 1 auto !important; }
  .flex-xxl-grow-0 { flex-grow: 0 !important; }
  .flex-xxl-grow-1 { flex-grow: 1 !important; }
  .flex-xxl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xxl-start { justify-content: flex-start !important; }
  .justify-content-xxl-end { justify-content: flex-end !important; }
  .justify-content-xxl-center { justify-content: center !important; }
  .justify-content-xxl-between { justify-content: space-between !important; }
  .justify-content-xxl-around { justify-content: space-around !important; }
  .align-items-xxl-start { align-items: flex-start !important; }
  .align-items-xxl-end { align-items: flex-end !important; }
  .align-items-xxl-center { align-items: center !important; }
  .align-items-xxl-baseline { align-items: baseline !important; }
  .align-items-xxl-stretch { align-items: stretch !important; }
  .align-content-xxl-start { align-content: flex-start !important; }
  .align-content-xxl-end { align-content: flex-end !important; }
  .align-content-xxl-center { align-content: center !important; }
  .align-content-xxl-between { align-content: space-between !important; }
  .align-content-xxl-around { align-content: space-around !important; }
  .align-content-xxl-stretch { align-content: stretch !important; }
  .align-self-xxl-auto { align-self: auto !important; }
  .align-self-xxl-start { align-self: flex-start !important; }
  .align-self-xxl-end { align-self: flex-end !important; }
  .align-self-xxl-center { align-self: center !important; }
  .align-self-xxl-baseline { align-self: baseline !important; }
  .align-self-xxl-stretch { align-self: stretch !important; } }

@media (min-width: 1600px) { .flex-xxxl-row { flex-direction: row !important; }
  .flex-xxxl-column { flex-direction: column !important; }
  .flex-xxxl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xxxl-wrap { flex-wrap: wrap !important; }
  .flex-xxxl-nowrap { flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill { flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 { flex-grow: 0 !important; }
  .flex-xxxl-grow-1 { flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xxxl-start { justify-content: flex-start !important; }
  .justify-content-xxxl-end { justify-content: flex-end !important; }
  .justify-content-xxxl-center { justify-content: center !important; }
  .justify-content-xxxl-between { justify-content: space-between !important; }
  .justify-content-xxxl-around { justify-content: space-around !important; }
  .align-items-xxxl-start { align-items: flex-start !important; }
  .align-items-xxxl-end { align-items: flex-end !important; }
  .align-items-xxxl-center { align-items: center !important; }
  .align-items-xxxl-baseline { align-items: baseline !important; }
  .align-items-xxxl-stretch { align-items: stretch !important; }
  .align-content-xxxl-start { align-content: flex-start !important; }
  .align-content-xxxl-end { align-content: flex-end !important; }
  .align-content-xxxl-center { align-content: center !important; }
  .align-content-xxxl-between { align-content: space-between !important; }
  .align-content-xxxl-around { align-content: space-around !important; }
  .align-content-xxxl-stretch { align-content: stretch !important; }
  .align-self-xxxl-auto { align-self: auto !important; }
  .align-self-xxxl-start { align-self: flex-start !important; }
  .align-self-xxxl-end { align-self: flex-end !important; }
  .align-self-xxxl-center { align-self: center !important; }
  .align-self-xxxl-baseline { align-self: baseline !important; }
  .align-self-xxxl-stretch { align-self: stretch !important; } }

.float-left { float: left !important; }

.float-right { float: right !important; }

.float-none { float: none !important; }

@media (min-width: 576px) { .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; } }

@media (min-width: 768px) { .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; } }

@media (min-width: 992px) { .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; } }

@media (min-width: 1200px) { .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; } }

@media (min-width: 1366px) { .float-xxl-left { float: left !important; }
  .float-xxl-right { float: right !important; }
  .float-xxl-none { float: none !important; } }

@media (min-width: 1600px) { .float-xxxl-left { float: left !important; }
  .float-xxxl-right { float: right !important; }
  .float-xxxl-none { float: none !important; } }

.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

@media (min-width: 1366px) { .m-xxl-0 { margin: 0 !important; }
  .mt-xxl-0, .my-xxl-0 { margin-top: 0 !important; }
  .mr-xxl-0, .mx-xxl-0 { margin-right: 0 !important; }
  .mb-xxl-0, .my-xxl-0 { margin-bottom: 0 !important; }
  .ml-xxl-0, .mx-xxl-0 { margin-left: 0 !important; }
  .m-xxl-1 { margin: 0.25rem !important; }
  .mt-xxl-1, .my-xxl-1 { margin-top: 0.25rem !important; }
  .mr-xxl-1, .mx-xxl-1 { margin-right: 0.25rem !important; }
  .mb-xxl-1, .my-xxl-1 { margin-bottom: 0.25rem !important; }
  .ml-xxl-1, .mx-xxl-1 { margin-left: 0.25rem !important; }
  .m-xxl-2 { margin: 0.5rem !important; }
  .mt-xxl-2, .my-xxl-2 { margin-top: 0.5rem !important; }
  .mr-xxl-2, .mx-xxl-2 { margin-right: 0.5rem !important; }
  .mb-xxl-2, .my-xxl-2 { margin-bottom: 0.5rem !important; }
  .ml-xxl-2, .mx-xxl-2 { margin-left: 0.5rem !important; }
  .m-xxl-3 { margin: 1rem !important; }
  .mt-xxl-3, .my-xxl-3 { margin-top: 1rem !important; }
  .mr-xxl-3, .mx-xxl-3 { margin-right: 1rem !important; }
  .mb-xxl-3, .my-xxl-3 { margin-bottom: 1rem !important; }
  .ml-xxl-3, .mx-xxl-3 { margin-left: 1rem !important; }
  .m-xxl-4 { margin: 1.5rem !important; }
  .mt-xxl-4, .my-xxl-4 { margin-top: 1.5rem !important; }
  .mr-xxl-4, .mx-xxl-4 { margin-right: 1.5rem !important; }
  .mb-xxl-4, .my-xxl-4 { margin-bottom: 1.5rem !important; }
  .ml-xxl-4, .mx-xxl-4 { margin-left: 1.5rem !important; }
  .m-xxl-5 { margin: 3rem !important; }
  .mt-xxl-5, .my-xxl-5 { margin-top: 3rem !important; }
  .mr-xxl-5, .mx-xxl-5 { margin-right: 3rem !important; }
  .mb-xxl-5, .my-xxl-5 { margin-bottom: 3rem !important; }
  .ml-xxl-5, .mx-xxl-5 { margin-left: 3rem !important; }
  .p-xxl-0 { padding: 0 !important; }
  .pt-xxl-0, .py-xxl-0 { padding-top: 0 !important; }
  .pr-xxl-0, .px-xxl-0 { padding-right: 0 !important; }
  .pb-xxl-0, .py-xxl-0 { padding-bottom: 0 !important; }
  .pl-xxl-0, .px-xxl-0 { padding-left: 0 !important; }
  .p-xxl-1 { padding: 0.25rem !important; }
  .pt-xxl-1, .py-xxl-1 { padding-top: 0.25rem !important; }
  .pr-xxl-1, .px-xxl-1 { padding-right: 0.25rem !important; }
  .pb-xxl-1, .py-xxl-1 { padding-bottom: 0.25rem !important; }
  .pl-xxl-1, .px-xxl-1 { padding-left: 0.25rem !important; }
  .p-xxl-2 { padding: 0.5rem !important; }
  .pt-xxl-2, .py-xxl-2 { padding-top: 0.5rem !important; }
  .pr-xxl-2, .px-xxl-2 { padding-right: 0.5rem !important; }
  .pb-xxl-2, .py-xxl-2 { padding-bottom: 0.5rem !important; }
  .pl-xxl-2, .px-xxl-2 { padding-left: 0.5rem !important; }
  .p-xxl-3 { padding: 1rem !important; }
  .pt-xxl-3, .py-xxl-3 { padding-top: 1rem !important; }
  .pr-xxl-3, .px-xxl-3 { padding-right: 1rem !important; }
  .pb-xxl-3, .py-xxl-3 { padding-bottom: 1rem !important; }
  .pl-xxl-3, .px-xxl-3 { padding-left: 1rem !important; }
  .p-xxl-4 { padding: 1.5rem !important; }
  .pt-xxl-4, .py-xxl-4 { padding-top: 1.5rem !important; }
  .pr-xxl-4, .px-xxl-4 { padding-right: 1.5rem !important; }
  .pb-xxl-4, .py-xxl-4 { padding-bottom: 1.5rem !important; }
  .pl-xxl-4, .px-xxl-4 { padding-left: 1.5rem !important; }
  .p-xxl-5 { padding: 3rem !important; }
  .pt-xxl-5, .py-xxl-5 { padding-top: 3rem !important; }
  .pr-xxl-5, .px-xxl-5 { padding-right: 3rem !important; }
  .pb-xxl-5, .py-xxl-5 { padding-bottom: 3rem !important; }
  .pl-xxl-5, .px-xxl-5 { padding-left: 3rem !important; }
  .m-xxl-auto { margin: auto !important; }
  .mt-xxl-auto, .my-xxl-auto { margin-top: auto !important; }
  .mr-xxl-auto, .mx-xxl-auto { margin-right: auto !important; }
  .mb-xxl-auto, .my-xxl-auto { margin-bottom: auto !important; }
  .ml-xxl-auto, .mx-xxl-auto { margin-left: auto !important; } }

@media (min-width: 1600px) { .m-xxxl-0 { margin: 0 !important; }
  .mt-xxxl-0, .my-xxxl-0 { margin-top: 0 !important; }
  .mr-xxxl-0, .mx-xxxl-0 { margin-right: 0 !important; }
  .mb-xxxl-0, .my-xxxl-0 { margin-bottom: 0 !important; }
  .ml-xxxl-0, .mx-xxxl-0 { margin-left: 0 !important; }
  .m-xxxl-1 { margin: 0.25rem !important; }
  .mt-xxxl-1, .my-xxxl-1 { margin-top: 0.25rem !important; }
  .mr-xxxl-1, .mx-xxxl-1 { margin-right: 0.25rem !important; }
  .mb-xxxl-1, .my-xxxl-1 { margin-bottom: 0.25rem !important; }
  .ml-xxxl-1, .mx-xxxl-1 { margin-left: 0.25rem !important; }
  .m-xxxl-2 { margin: 0.5rem !important; }
  .mt-xxxl-2, .my-xxxl-2 { margin-top: 0.5rem !important; }
  .mr-xxxl-2, .mx-xxxl-2 { margin-right: 0.5rem !important; }
  .mb-xxxl-2, .my-xxxl-2 { margin-bottom: 0.5rem !important; }
  .ml-xxxl-2, .mx-xxxl-2 { margin-left: 0.5rem !important; }
  .m-xxxl-3 { margin: 1rem !important; }
  .mt-xxxl-3, .my-xxxl-3 { margin-top: 1rem !important; }
  .mr-xxxl-3, .mx-xxxl-3 { margin-right: 1rem !important; }
  .mb-xxxl-3, .my-xxxl-3 { margin-bottom: 1rem !important; }
  .ml-xxxl-3, .mx-xxxl-3 { margin-left: 1rem !important; }
  .m-xxxl-4 { margin: 1.5rem !important; }
  .mt-xxxl-4, .my-xxxl-4 { margin-top: 1.5rem !important; }
  .mr-xxxl-4, .mx-xxxl-4 { margin-right: 1.5rem !important; }
  .mb-xxxl-4, .my-xxxl-4 { margin-bottom: 1.5rem !important; }
  .ml-xxxl-4, .mx-xxxl-4 { margin-left: 1.5rem !important; }
  .m-xxxl-5 { margin: 3rem !important; }
  .mt-xxxl-5, .my-xxxl-5 { margin-top: 3rem !important; }
  .mr-xxxl-5, .mx-xxxl-5 { margin-right: 3rem !important; }
  .mb-xxxl-5, .my-xxxl-5 { margin-bottom: 3rem !important; }
  .ml-xxxl-5, .mx-xxxl-5 { margin-left: 3rem !important; }
  .p-xxxl-0 { padding: 0 !important; }
  .pt-xxxl-0, .py-xxxl-0 { padding-top: 0 !important; }
  .pr-xxxl-0, .px-xxxl-0 { padding-right: 0 !important; }
  .pb-xxxl-0, .py-xxxl-0 { padding-bottom: 0 !important; }
  .pl-xxxl-0, .px-xxxl-0 { padding-left: 0 !important; }
  .p-xxxl-1 { padding: 0.25rem !important; }
  .pt-xxxl-1, .py-xxxl-1 { padding-top: 0.25rem !important; }
  .pr-xxxl-1, .px-xxxl-1 { padding-right: 0.25rem !important; }
  .pb-xxxl-1, .py-xxxl-1 { padding-bottom: 0.25rem !important; }
  .pl-xxxl-1, .px-xxxl-1 { padding-left: 0.25rem !important; }
  .p-xxxl-2 { padding: 0.5rem !important; }
  .pt-xxxl-2, .py-xxxl-2 { padding-top: 0.5rem !important; }
  .pr-xxxl-2, .px-xxxl-2 { padding-right: 0.5rem !important; }
  .pb-xxxl-2, .py-xxxl-2 { padding-bottom: 0.5rem !important; }
  .pl-xxxl-2, .px-xxxl-2 { padding-left: 0.5rem !important; }
  .p-xxxl-3 { padding: 1rem !important; }
  .pt-xxxl-3, .py-xxxl-3 { padding-top: 1rem !important; }
  .pr-xxxl-3, .px-xxxl-3 { padding-right: 1rem !important; }
  .pb-xxxl-3, .py-xxxl-3 { padding-bottom: 1rem !important; }
  .pl-xxxl-3, .px-xxxl-3 { padding-left: 1rem !important; }
  .p-xxxl-4 { padding: 1.5rem !important; }
  .pt-xxxl-4, .py-xxxl-4 { padding-top: 1.5rem !important; }
  .pr-xxxl-4, .px-xxxl-4 { padding-right: 1.5rem !important; }
  .pb-xxxl-4, .py-xxxl-4 { padding-bottom: 1.5rem !important; }
  .pl-xxxl-4, .px-xxxl-4 { padding-left: 1.5rem !important; }
  .p-xxxl-5 { padding: 3rem !important; }
  .pt-xxxl-5, .py-xxxl-5 { padding-top: 3rem !important; }
  .pr-xxxl-5, .px-xxxl-5 { padding-right: 3rem !important; }
  .pb-xxxl-5, .py-xxxl-5 { padding-bottom: 3rem !important; }
  .pl-xxxl-5, .px-xxxl-5 { padding-left: 3rem !important; }
  .m-xxxl-auto { margin: auto !important; }
  .mt-xxxl-auto, .my-xxxl-auto { margin-top: auto !important; }
  .mr-xxxl-auto, .mx-xxxl-auto { margin-right: auto !important; }
  .mb-xxxl-auto, .my-xxxl-auto { margin-bottom: auto !important; }
  .ml-xxxl-auto, .mx-xxxl-auto { margin-left: auto !important; } }

.text-justify { text-align: justify !important; }

.text-nowrap { white-space: nowrap !important; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

@media (min-width: 1366px) { .text-xxl-left { text-align: left !important; }
  .text-xxl-right { text-align: right !important; }
  .text-xxl-center { text-align: center !important; } }

@media (min-width: 1600px) { .text-xxxl-left { text-align: left !important; }
  .text-xxxl-right { text-align: right !important; }
  .text-xxxl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

/* Simplemenu blocks */
ul#simplemenu:after { content: ''; display: block; height: 0; clear: both; }

ul#simplemenu li .nolink, ul#simplemenu li a { font-size: 1rem; line-height: 1rem; padding: 5px 6px 5px 6px; }

ul#simplemenu li .nolink { width: auto; display: block; }

ul#simplemenu .sfHover ul, ul#simplemenu li:hover ul { top: 1.6875rem; }

@media (max-width: 991.98px) { #topbar { position: fixed; } }

#block-subscribe input[type="submit"] { margin: 1rem 0; }

.region-footer-bottom { font-size: 0.875rem; }

#block-footer-menu li.leaf { display: inline-block; padding: 0 0.5rem; }

.map-container { position: relative; width: 100%; height: 0; padding-bottom: 50%; }

.map-container iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.slick--view--front-carousel { position: relative; }

.slick--view--front-carousel .slick-dotted { position: relative; }

.slick--view--front-carousel .slick-dots { position: absolute; padding: 0; width: 100%; bottom: 20px; text-align: center; }

.slick--view--front-carousel .slick-dots li { text-indent: -9999px; position: relative; display: inline-block; width: 20px; height: 20px; list-style: none; margin: 5px; cursor: pointer; }

.slick--view--front-carousel .slick-dots li:after { position: absolute; top: 5px; left: 5px; content: ''; display: block; width: 10px; height: 10px; border-radius: 50%; background-color: #fff; }

.slick--view--front-carousel .slick-dots li.slick-active:after { background-color: #b00024; }

.slick--view--front-carousel .slick__arrow { position: absolute; width: 100%; top: calc(50% - 1.5rem); height: 0; }

@media (min-width: 768px) { .slick--view--front-carousel .slick__arrow { top: calc(50% - 2rem); } }

.slick--view--front-carousel .slick__arrow button { color: #fff; border: 2px solid #aaa; background: rgba(0, 0, 0, 0.3); height: 3rem; width: 3rem; font-size: 1.5rem; position: absolute; transition: all 0.3s; }

.slick--view--front-carousel .slick__arrow button:hover { background-color: #f7c221; border-color: #f7c221; }

@media (min-width: 768px) { .slick--view--front-carousel .slick__arrow button { height: 4rem; width: 4rem; font-size: 2rem; } }

.slick--view--front-carousel .slick-prev { left: 1rem; }

.slick--view--front-carousel .slick-next { right: 1rem; }

.slick--view--front-carousel--lecture-carousel .views-field-field-image img { width: 100%; height: auto; }

ul.quicktabs-tabs { text-align: center; background-color: transparent; }

ul.quicktabs-tabs li { display: inline-block; }

form#user-login { margin: 4em 0; }

form#user-login > div { max-width: 350px; margin: 2rem auto 0; }

form#user-login .form-item label { text-align: left; }

form#user-login input[type="text"], form#user-login input[type="password"] { width: 100%; }

form#user-login input[type="submit"] { width: 100%; }

form#user-login .form-required { display: none; }

#user-register-form > div { margin: 2rem auto; }

#block-menu-menu-top-menu { padding: 0.5rem 0; }

#block-menu-menu-top-menu .menu { text-align: right; margin-bottom: 0; }

#block-menu-menu-top-menu li.leaf { display: inline-block; margin: 0 0.5rem; }

#block-menu-menu-top-menu li.with-icon { text-align: left; }

#block-menu-menu-top-menu li.with-icon a { display: inline-block; position: relative; width: 1.2rem; height: 1.2rem; text-indent: -9999px; }

#block-menu-menu-top-menu li.with-icon a:before { display: inline-block; font-family: "FontAwesome","Font Awesome 5 Brands","Font Awesome 5 Free"; margin-right: 0.1875rem; font-size: 1.2rem; width: 1.2rem; height: 1.2rem; position: absolute; text-indent: 0; left: 0; top: 0; }

#block-menu-menu-top-menu li.with-icon.fb-icon a:before { content: '\f230'; }

#block-menu-menu-top-menu li.with-icon.youtube-icon a:before { content: '\f16a'; }

@media (max-width: 991.98px) { #block-menu-menu-top-menu { background-color: #f5f5f5; }
  #block-menu-menu-top-menu .menu { text-align: center; }
  #block-menu-menu-top-menu a { color: #000; } }

#block-menu-menu-about .menu, .pane-menu-menu-about .menu { text-align: center; }

#block-menu-menu-about .leaf, .pane-menu-menu-about .leaf { display: inline-block; margin: 0 1rem; font-size: 1.25rem; }

img.file-icon { width: auto; }

.node-form .form-item { margin: 1.25rem 0; }

.node-form .form-item.form-type-textarea { margin-bottom: 0; }

.node-form .filter-wrapper { padding: 8px 0; background-color: #efefef; }

.node-form table .form-item, .node-form .filter-wrapper .form-item, .node-form .image-widget-data .form-item, .node-form .date-form-element-content .form-item { margin: 5px 0; }

.node-content h1 { font-size: 22px; }

.node-content h2 { font-size: 20px; }

.node-content h3 { font-size: 18px; }

.node-content h4 { font-size: 16px; }

.node-content h5 { font-size: 14px; }

.node-content h6 { font-size: 13px; }

.node-content ul { list-style: disc; padding-left: 1.25em; }

.node-content .blog-carousel-desc img { max-width: 100%; height: auto !important; }

.node-type-about .slick { position: relative; }

.node-type-about .slide img { width: auto; height: auto; max-width: 100%; }

.node-type-about .slick-dotted { position: relative; }

.node-type-about .slick-dots { text-align: center; padding: 1rem 0 0.5rem; }

.node-type-about .slick-dots li { text-indent: -9999px; position: relative; display: inline-block; width: 20px; height: 20px; list-style: none; margin: 5px; cursor: pointer; }

.node-type-about .slick-dots li:after { position: absolute; top: 5px; left: 5px; content: ''; display: block; width: 10px; height: 10px; border-radius: 50%; box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); background: rgba(255, 255, 255, 0.5); }

.node-type-about .slick-dots li.slick-active:after { background-color: #f7c221; }

.node-type-about .slick__arrow { position: absolute; width: 100%; top: calc(43% - 1.25rem); height: 0; }

.node-type-about .slick__arrow button { color: #fff; border: 2px solid #aaa; background: rgba(0, 0, 0, 0.3); height: 2.5rem; width: 2.5rem; font-size: 1.5rem; position: absolute; transition: all 0.3s; }

.node-type-about .slick__arrow button:hover { background-color: #f7c221; border-color: #f7c221; }

.node-type-about .slick-prev { left: 1rem; }

.node-type-about .slick-next { right: 1rem; }

.node-type-about #members ul { list-style: none; padding: 0; }

.node-type-project .blog-masonry, .node-type-project .blog-masonry .blog-carousel { border: 0; }

.node-type-project .views-field-field-performance .container { max-width: 100%; padding-left: 0; padding-right: 0; }

.node-type-project .stat { background-size: cover; padding: 30px 0 50px; background-color: #6A0003; }

.node-type-history .slick { position: relative; }

.node-type-history .slide img { width: auto; height: auto; max-width: 100%; }

.node-type-history .slick__arrow { position: absolute; width: 100%; top: calc(43% - 1.25rem); height: 0; }

.node-type-history .slick__arrow button { color: #fff; border: 2px solid #aaa; background: rgba(0, 0, 0, 0.3); height: 2.5rem; width: 2.5rem; font-size: 1.5rem; position: absolute; transition: all 0.3s; border-radius: 0; }

.node-type-history .slick__arrow button:hover { background-color: #f7c221; border-color: #f7c221; }

.node-type-history .slick-prev:before, .node-type-history .slick-next:before { display: none; }

.node-type-history .slick-prev { left: 1rem; }

.node-type-history .slick-next { right: 1rem; }

/* ------------ 首頁 ------------ */
.front .pane-carousel { margin-bottom: 100px; }

.front .pane-project { margin-bottom: 3rem; }

.front .pane-cust-blk .pane-title { text-align: center; font-size: 1.8rem; margin-top: 0; margin-bottom: 2rem; }

.front .pane-testimonial { margin-bottom: 3rem; }

.front .pane-testimonial .pane-title { text-align: center; font-size: 1.8rem; margin-bottom: 2rem; }

.front .pane-testimonial .testimonial-wrap { text-align: left; }

.front .pane-testimonial .media { margin: 0 0 15px; }

.front .pane-testimonial .media img { width: 100%; height: auto; }

.front .pane-testimonial .testimonial-names h3 { margin: 0; padding: 0; }

.pane-front-carousel .slick { position: relative; }

.pane-front-carousel .bg-wrapper { height: 56.25vw; background: center no-repeat; background-size: cover; position: relative; box-sizing: border-box; }

@media (min-width: 576px) { .pane-front-carousel .bg-wrapper { height: 56.25vw; } }

.pane-front-carousel .views-field-field-cover:before, .pane-front-carousel .views-field-field-cover:after { position: absolute; width: 100%; height: 100%; content: ''; display: block; }

.pane-front-carousel .views-field-field-cover:before { background-image: linear-gradient(129deg, rgba(247, 248, 216, 0.4), rgba(254, 255, 215, 0)); }

.pane-front-carousel .views-field-field-cover:after { background-color: rgba(0, 0, 0, 0.4); }

.pane-front-carousel .views-field-nothing { max-width: 1280px; margin-left: auto; margin-right: auto; box-sizing: border-box; padding-top: 9%; padding-left: 50px; position: relative; z-index: 1; }

.pane-front-carousel .title { font-size: 64px; font-weight: bold; letter-spacing: 5px; color: #fff; text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2); margin-bottom: 20px; }

.pane-front-carousel .desc { color: #fff; max-width: 450px; font-size: 18px; letter-spacing: 1px; text-shadow: 0 0 3px rgba(0, 0, 0, 0.3); margin-bottom: 32px; }

.pane-front-carousel .btn { padding: 14px; width: 240px; }

#views-exposed-form-crm-event-cp-events { display: none; }

#events-menu li.active a, #events-menu li:hover a { background-color: #000; }

.page-news .panel-pane.container { max-width: 1000px; }

.page-news .portfolio_item img { height: auto; }

#block-news-cata .pane-content { text-align: center; }

#block-news-cata .pane-content li { display: inline-block; margin: 5px; }

#block-news-cata .pane-content li a { display: inline-block; border-radius: 3px; padding: 6px 12px; background-color: #f7c221; color: #fff; transition: background-color .2s linear, color .2s linear; }

#block-news-cata .pane-content li:hover a, #block-news-cata .pane-content li.active a { background-color: #333; }

#block-statement-menu .pane-content { text-align: center; }

#block-statement-menu .pane-content li { display: inline-block; margin: 5px; }

#block-statement-menu .pane-content li a { display: inline-block; border-radius: 3px; padding: 6px 12px; background-color: #f7c221; color: #fff; transition: background-color .2s linear, color .2s linear; }

#block-statement-menu .pane-content li:hover a, #block-statement-menu .pane-content li.active a { background-color: #333; }

.pane-record-cp-records .views-row { border-bottom: 1px solid rgba(106, 115, 123, 0.3); }

.pane-record-cp-records td { padding: 0.675rem 0.5rem; }

.pane-record-cp-records .date { width: 120px; }

@media (min-width: 576px) { .pane-record-cp-records .date { width: 250px; } }

.page-faqs .pane-taxonomy-menu-block-3 .pane-content ul { list-style: none; }

.page-faqs .pane-taxonomy-menu-block-3 .pane-content li { display: inline-block; margin: 0 1rem; font-size: 1.25rem; }

.page-faqs .pane-taxonomy-menu-block-3 .pane-content li a { display: block; text-align: center; padding: 7px 18px; }

.page-faqs .pane-taxonomy-menu-block-3 .pane-content li.active a, .page-faqs .pane-taxonomy-menu-block-3 .pane-content li:hover a { background-color: #f7c221; border-radius: 3px; color: #fff; }

/**------------------- UI node form --------------------**/
.page-node-edit .node-form, .page-node-edit .tabs { max-width: 1170px; margin-left: auto; margin-right: auto; box-sizing: border-box; padding: 0 15px; }

.node-form { margin-bottom: 2rem; }

.node-form input[type="file"] { display: inline-block; }

.node-form .form-actions > input { margin-right: 20px; }

.node-form .end-date-wrapper .date-padding { float: none; }

.page-civicrm-event ul, .page-civicrm-event ol { font-size: 1rem; }

.page-civicrm-event .section-separator { border-bottom: 3px solid #f7c221; margin-bottom: 1.5rem; }

.page-civicrm-event a.faq-link.button { font-size: 1.5rem; padding: 15px 30px; font-weight: 500; box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); background-color: #f7c121; }

.crm-container .label { color: #333; }

.crm-container img { max-width: 100%; }

.crm-event-img img { width: 100%; height: auto; }
