.quicktabs-wrapper{

}
ul.quicktabs-tabs{
  text-align: center;
  background-color: transparent;

  li{
    display: inline-block;
    a{}
    &.active{

    }
  }
}
