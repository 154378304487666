@import "vars";
@import "mixins";
@import "utilities";
/*
 * Simplemenu blocks
 */

ul#simplemenu:after{
  content:'';
  display: block;
  height:0;
  clear: both;
}
ul#simplemenu li .nolink, ul#simplemenu li a {
  font-size: 1rem;
  line-height: 1rem;
  padding: 5px 6px 5px 6px;
}
ul#simplemenu li .nolink {
  width: auto;
  display: block;
}
ul#simplemenu .sfHover ul, ul#simplemenu li:hover ul {
  top: 1.6875rem;
}

//----------頁首輔助選單-----------
#topbar{

}

@include media-breakpoint-down(md){
  body{

  }
  #topbar{
    position: fixed;
  }

}

//----------頁首輔助選單 End-----------

//----------頁尾-----------

#block-subscribe{
  input[type="submit"]{
    margin: 1rem 0;
  }
}

.region-footer-bottom{
  font-size: 0.875rem;
}
#block-footer-menu{
  li.leaf{display: inline-block;padding: 0 0.5rem;}
}

.map-container{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//----------頁尾 End-----------

//------components--------
@import "components/slick";
@import "components/quicktabs";
@import "components/form";
//----------components End-----------

//------block--------
@import "block/top_menu";
@import "block/menu_about";
//----------block End-----------

//----------Node----------
@import "node/node_common";
@import "node/about";
@import "node/project";
@import "node/history";
//----------Node End-----------

@import "pages/front";
@import "pages/events";
@import "pages/news";
@import "pages/statement";
@import "pages/records";
@import "pages/faqs";

@import "pages/node_edit";

@import "crm";
