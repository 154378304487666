#user-register-form{
  >div{
    margin: 2rem auto;
  }

  .form-item-mail{

  }

}
