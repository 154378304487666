.page-records{

}
.pane-record-cp-records{
  .views-row{
    border-bottom: 1px solid rgba(106, 115, 123, .3);
  }
  td{
    padding: 0.675rem 0.5rem;
  }
  .date{
    width: 120px;
    @include media-breakpoint-up(sm){
      width: 250px;
    }
  }
  .title{}
  .date-separator{

  }
}
