.node-type-project{
  .blog-masonry{
    &, .blog-carousel{
      border: 0;
    }
  }
  .views-field-field-performance{
    .container{
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .stat{
    //background: url("../demos/parallax_08.jpg") center fixed no-repeat;
    background-size: cover;
    padding: 30px 0 50px;
    background-color: #6A0003;
  }
}

