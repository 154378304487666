.node-type-history{
  .slick{
    position: relative;
  }
  .slide{
    img{
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
  //.slick-dotted {
  //  position: relative;
  //}
  //.slick-dots {
  //  //position: absolute;
  //  //padding: 0;
  //  //width: 100%;
  //  //bottom: 20px;
  //  text-align: center;
  //  padding:1rem 0 0.5rem;
  //  li {
  //    text-indent: -9999px;
  //    position: relative;
  //    display: inline-block;
  //    width: 20px;
  //    height: 20px;
  //    list-style: none;
  //    margin: 5px;
  //    cursor: pointer;
  //    &:after {
  //      position: absolute;
  //      top: 5px;
  //      left: 5px;
  //      content: '';
  //      display: block;
  //      width: 10px;
  //      height: 10px;
  //      border-radius: 50%;
  //      box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
  //      //background-color: #fff;
  //      background: rgba(255,255,255,0.5);
  //    }
  //    &.slick-active:after {
  //      //background-color: #b00024;
  //      background-color: #f7c221;
  //
  //    }
  //  }
  //}
  .slick__arrow{
    position: absolute;
    width: 100%;
    top: calc(43% - 1.25rem);
    height: 0;

    button{
      color: #fff;
      border: 2px solid #aaa;
      background: rgba(0, 0, 0, 0.3);
      height: 2.5rem;
      width: 2.5rem;
      font-size: 1.5rem;
      position: absolute;
      transition: all 0.3s;
      border-radius: 0;
      &:hover{
        background-color: #f7c221;
        border-color:#f7c221;
      }
    }
  }
  .slick-prev,.slick-next{
    &:before{display: none;}
  }
  .slick-prev{
    left: 1rem;
  }
  .slick-next{
    right: 1rem;
  }
}

