.page-faqs{
  .pane-taxonomy-menu-block-3 .pane-content{
    ul{list-style: none;}
    li{
      display: inline-block;
      margin: 0 1rem;
      font-size: 1.25rem;
      a{
        display: block;
        text-align: center;
        padding: 7px 18px;
      }

      &.active,&:hover{
        a{
          background-color: $color-yellow;
          border-radius: 3px;
          color: #fff;
        }
      }
    }
  }
}
