.page-statement-list{

}

#block-statement-menu{
  .pane-content{
    text-align: center;
    li{
      display: inline-block;
      margin: 5px;

      a{
        display: inline-block;
        border-radius: 3px;
        padding: 6px 12px;
        background-color: $color-btn-primary;
        color: #fff;
        transition: background-color .2s linear, color .2s linear;
      }

      &:hover,&.active {
        a{
          background-color: $color-btn-primary-hover;
        }
      }
    }
  }
}
