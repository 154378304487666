#block-menu-menu-about,.pane-menu-menu-about{
  .menu{
    text-align: center;
  }
  .leaf{
    display: inline-block;
    margin: 0 1rem;
    font-size: 1.25rem;

    a{

    }
  }
}
