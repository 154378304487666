#views-exposed-form-crm-event-cp-events{display: none;}

#events-menu{
  li{
    a{

    }

    &.active,&:hover{
      a{
        background-color: #000;
      }
    }
  }
}
