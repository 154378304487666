.page-civicrm-event{
  ul,ol{font-size: 1rem;}
  .section-separator {
    border-bottom: 3px solid #f7c221;
    margin-bottom: 1.5rem;
  }
  a.faq-link.button{
    font-size: 1.5rem;
    padding: 15px 30px;
    font-weight: 500;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    background-color: #f7c121;
  }
}

.crm-container{
  .label{
    color: #333;
  }
  img{max-width: 100%;}
  table[style]{}
}

.crm-event-img{
  img{
    width: 100%;height: auto;
  }
}
