.pane-carousel{

}
.slick--view--front-carousel{
  position: relative;

  .slick-dotted {
    position: relative;
  }
  .slick-dots {
    position: absolute;
    padding: 0;
    width: 100%;
    bottom: 20px;
    text-align: center;
    li {
      text-indent: -9999px;
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      list-style: none;
      margin: 5px;
      cursor: pointer;
      &:after {
        position: absolute;
        top: 5px;
        left: 5px;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
      }
      &.slick-active:after {
        background-color: #b00024;
      }
    }
  }
  .slick__arrow{
    position: absolute;
    width: 100%;
    top: calc(50% - 1.5rem);
    height: 0;
    @include media-breakpoint-up(md){
      top: calc(50% - 2rem);
    }

    button{
      color: #fff;
      border: 2px solid #aaa;
      background: rgba(0, 0, 0, 0.3);
      height: 3rem;
      width: 3rem;
      font-size: 1.5rem;
      position: absolute;
      transition: all 0.3s;
      &:hover{
        background-color: #f7c221;
        border-color:#f7c221;
      }

      @include media-breakpoint-up(md){
        height: 4rem;
        width: 4rem;
        font-size: 2rem;
      }
    }
  }
  .slick-prev{
    left: 1rem;
  }
  .slick-next{
    right: 1rem;
  }
}

.slick--view--front-carousel--lecture-carousel{
  .views-field-field-image img{
    width: 100%;
    height: auto;
  }
}
