/* ------------
* 首頁
* ------------ */
.front {
  .pane-carousel{
    margin-bottom: 100px;
  }

  .pane-project{
    margin-bottom: 3rem;
  }

  .pane-cust-blk{
    .pane-title{
      text-align: center;
      font-size: 1.8rem;
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }

  .pane-testimonial{
    margin-bottom: 3rem;

    .pane-title{
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    .testimonial-wrap{
      text-align: left;
    }

    .media{
      margin: 0 0 15px;
      img{
        width: 100%;
        height: auto;
      }

    }

    .testimonial-names h3{
      margin: 0;
      padding: 0;
    }
  }


}

//輪播
.pane-front-carousel {
  .slick{
    position: relative;
  }

  .bg-wrapper {
    //height: 300px;
    height: 56.25vw;
    background: center no-repeat;
    background-size: cover;
    position: relative;
    box-sizing: border-box;
    @include media-breakpoint-up(sm){
      height: 56.25vw;
    }
    @include media-breakpoint-up(xl){
      //height: 630px;
    }
    @include media-breakpoint-up(xxl){
    }
  }
  .views-field-field-cover {
    &:before, &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
    }
    &:before {
      background-image: linear-gradient(129deg, rgba(247, 248, 216, 0.4), rgba(254, 255, 215, 0));
    }
    &:after {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .views-field-nothing {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-top: 9%;
    padding-left: 50px;
    position: relative;
    z-index: 1;
  }
  .title {
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 5px;
    color: #fff;
    text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  .desc {
    color: #fff;
    max-width: 450px;
    font-size: 18px;
    letter-spacing: 1px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 32px;
  }
  .btn {
    padding: 14px;
    width: 240px;
  }
}
