/**-------------------
* UI
* node form
--------------------**/
.page-node-edit .node-form,
.page-node-edit .tabs{
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding:  0 15px;
}

.node-form{
  margin-bottom: 2rem;


  input[type="file"]{display: inline-block;}

  .image-widget input[type="submit"]{}

  .form-actions >input{
    margin-right: 20px;
  }

  .end-date-wrapper{
    .date-padding{
      float: none;
    }
  }
}
