#block-menu-menu-top-menu{
  padding: 0.5rem 0;

  .menu{
    text-align: right;
    margin-bottom: 0;
  }
  li.leaf{
    display: inline-block;
    margin: 0 0.5rem;
  }
  li.with-icon{
    $icon_size:1.2rem;
    text-align: left;
    a{
      display: inline-block;
      position: relative;
      width: $icon_size;
      height: $icon_size;
      text-indent: -9999px;
    }
    a:before{
      display: inline-block;
      font-family: "FontAwesome","Font Awesome 5 Brands","Font Awesome 5 Free";
      margin-right: 0.15625 * $icon_size;
      font-size: $icon_size;
      width: $icon_size;
      height: $icon_size;
      position: absolute;
      text-indent: 0;
      left: 0;
      top: 0;

    }
    &.fb-icon a:before{
      content: '\f230';
      //content: '\f082';
    }
    &.youtube-icon a:before{
      content: '\f16a';
      //content: '\f167';
    }

  }

  @media all and (max-width: 979px){

  }

  @include media-breakpoint-down(md){
    .menu{text-align: center;}
    background-color: #f5f5f5;

    a{
      color: #000;
    }
  }
}
