img.file-icon{
  width: auto;
}

.node-form{
  .form-item{
    margin: 1.25rem 0;
    &.form-type-textarea{
      margin-bottom: 0;
    }
  }
  .filter-wrapper{
    padding: 8px 0;
    background-color: #efefef;
  }
  table,.filter-wrapper,.image-widget-data,.date-form-element-content{
    .form-item{
      margin: 5px 0;
    }
  }
}

.node-content{
  h1{
    font-size: 22px;
  }
  h2{
    font-size: 20px;
  }
  h3{
    font-size: 18px;
  }
  h4{
    font-size: 16px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 13px;
  }
  ul{
    list-style: disc;
    padding-left: 1.25em;
  }

  .blog-carousel-desc{
    img{
      max-width: 100%;
      height: auto!important;
    }

  }
}
